// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// import "./channels/**/*_channel.js"

import * as Sentry from "@sentry/browser"

Sentry.init({
  dsn: window.sentry_dsn,
  environment: window.sentry_environment,
  tracesSampleRate: 0.5,
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

import "@hotwired/turbo-rails"
import "./controllers"

import anime from "animejs/lib/anime.es.js"
import Alpine from "alpinejs"
import intersect from "@alpinejs/intersect"
import persist from "@alpinejs/persist"
import focus from "@alpinejs/focus"
import collapse from "@alpinejs/collapse"
import Clipboard from "@ryangjchandler/alpine-clipboard"
import moment from "moment"

window.anime = anime
window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.plugin(persist)
Alpine.plugin(focus)
Alpine.plugin(Clipboard)
Alpine.plugin(collapse)
Alpine.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import("chartkick/chart.js")
// import("./components/topic_checkbox")
import("./components/license_slider")
import("./components/file_input")
import("./components/star_rating")
import("./components/user_cover_submit")
import("./components/theater_mode")
import("./components/resize")
import("./components/particles")
import("./components/direct_upload")

document.addEventListener(
  "turbo:load",
  (event) => {
    if (typeof window.dataLayer == "undefined") return

    window.dataLayer.push({ page_location: event.detail.url })
  },
  false
)

import Trix from "trix"

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false,
}

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false,
}

const { lang } = Trix.config

document.addEventListener("trix-before-initialize", () => {
  Trix.config.toolbar.getDefaultHTML = () =>
    `\
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
        </span>
        <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="trix-button flex items-center justify-center" data-trix-attribute="heading2" title="h2" tabindex="-1">H2</button>
          <button type="button" class="trix-button flex items-center justify-center" data-trix-attribute="heading3" title="h3" tabindex="-1">H3</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
        </span>
        <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
        </span>
        <span class="trix-button-group-spacer"></span>
        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
            <div class="trix-button-group">
              <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
              <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>\
    `
})

require("@rails/actiontext")

const installButton = document.querySelector("#install")
let installPrompt = null

function beforeInstallPrompt(event) {
  if (installButton) {
    event.preventDefault()
    installPrompt = event
    installButton.removeAttribute("hidden")
  }
}

window.addEventListener("beforeinstallprompt", beforeInstallPrompt)

if (installButton) {
  installButton.addEventListener("click", async () => {
    if (!installPrompt) {
      return
    }

    const result = await installPrompt.prompt()
    console.log(`Install prompt was: ${result.outcome}`)
    disableInAppInstallPrompt()
  })
}

function disableInAppInstallPrompt() {
  installPrompt = null
  installButton.setAttribute("hidden", "")
}
